<template>
  <div class="home">
    <BannerLibro/>
    <fondo />

    <edicion />
    <Films/>

    <seccion :profesional="1" titulo="Sección especial" clase="special_section" />
    <seccion :profesional="2" titulo="A Concurso Bonobo Pro" clase="contest_section" />
    <seccion :profesional="0" titulo="A Concurso Modalidad Móvil" clase="contest_section" />
    <seccion :extra="1" :customRandom="true" titulo="Más Material" clase="more_stuff_section"/>
    <seccion :old="1" titulo="Ediciones Pasadas" clase="past_editions_section"/>
    <patrocinadores />
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import Fondo from '@/components/home/Fondo.vue'
import Edicion from '@/components/home/Edicion.vue'
import Seccion from '@/components/home/Seccion.vue'
import Patrocinadores from '@/components/home/Patrocinadores.vue'
import BannerLibro from '@/components/BannerLibro.vue'
import Films from '@/components/home/Films.vue'

export default {
  name: 'Home',
  components: {
    Fondo,
    Edicion,
    Seccion,
    Patrocinadores,
    BannerLibro,
    Films
  },
  created: function(){
    this.$parent.loading = true;
  },
  mounted: function(){
    this.$parent.loading = false;
  }
}
</script>
